import { ReactComponent as BrandLogo } from "../../../Assets/newlogo.svg";
import Cross from "../../../Assets/cross.png";
import "./styles.scss";
import { Accordion, Card } from "react-bootstrap";
import { Nav, Navbar } from "reactstrap";

import { MdDashboard, MdNotificationsActive } from "react-icons/md";
import { AUTH } from "../../../utils/Util";
import NavToPage from "../NavToPage.js";

const users = [
  {
    to: "/create_new_backend_user",
    name: "Create New User",
    exact: false,
    Icon: MdNotificationsActive,
  },
  {
    to: "/view_users",
    name: "View Users",
    exact: true,
    Icon: MdNotificationsActive,
  },
];

const youtube = [
  {
    to: "/view_content",
    name: "Content Types",
    exact: true,
    Icon: MdNotificationsActive,
  },
  {
    to: "/view_channels",
    name: "Channels",
    exact: true,
    Icon: MdNotificationsActive,
  },
  {
    to: "/view_yt_links",
    name: "Youtube Links",
    exact: true,
    Icon: MdNotificationsActive,
  },
];

const Products = [
  {
    to: "/display_products",
    name: "View Products",
    exact: true,
    Icon: MdDashboard,
  },
  {
    to: "/file_uploader",
    name: "Upload Product files",
    exact: true,
    Icon: MdNotificationsActive,
  },
];

const Orders = [
  {
    to: "/customers_orders",
    name: "View Customers orders",
    exact: true,
    Icon: MdDashboard,
  },
  {
    to: "/supplier_order",
    name: "Place order",
    exact: true,
    Icon: MdNotificationsActive,
  },
];

const NavItems = [
  // {
  //   to: "/manjhi_special",
  //   name: "Manjhi Special",
  //   exact: true,
  //   Icon: MdDashboard,
  //   visible: "",
  // },
  {
    to: "/categories_n_commonName",
    name: "Categories & Common Name",
    exact: true,
    Icon: MdDashboard,
    visible: "admin",
  },
  {
    to: "/brands",
    name: "Brands",
    exact: true,
    Icon: MdDashboard,
    visible: "admin",
  },
];

const Sidebar = (props) => {
  let userType = AUTH.user.userType;
  return (
    // <aside style={{ position: "sticky", top: "0", zIndex: "100" }}>
    <div>
      {props.isMobile && (
        <img
          src={Cross}
          alt="cross"
          width={20}
          height={20}
          onClick={() => props.setDisplaySidebar(false)}
        />
      )}
      <Navbar
        style={{ textAlign: "center", color: "black", padding: "0.5rem" }}
      >
        <div className="brandicon">
          <BrandLogo
            className="brandLogo"
            style={{
              display: "block",
              width: 60,
              height: 60,
              cursor: "pointer",
              textAlign: "center",
              display: "block",
            }}
          />
        </div>
        <div className="brandname">
          <b>Ayurveda Hub</b>
        </div>
      </Navbar>

      <Nav vertical>
        {NavItems.map(({ visible, ...navObj }, index) => {
          if (!visible || (visible && userType === visible)) {
            return (
              <NavToPage
                key={index}
                navObj={navObj}
                setDisplaySidebar={props.setDisplaySidebar}
              />
            );
          } else {
            return null;
          }
        })}
        <Accordion style={{ width: "100%" }}>
          {userType !== "agent" && (
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <MdDashboard /> Products
              </Accordion.Toggle>
              {Products.map((navObj, index) => {
                if (
                  (userType === "admin" || index < 1) &&
                  userType !== "agent"
                ) {
                  return (
                    <Accordion.Collapse eventKey="0" key={index}>
                      <Card.Body>
                        <NavToPage
                          key={index}
                          navObj={navObj}
                          setDisplaySidebar={props.setDisplaySidebar}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  );
                } else {
                  return null;
                }
              })}
            </Card>
          )}

          {userType === "admin" && (
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <MdDashboard /> Youtube
              </Accordion.Toggle>
              {youtube.map((navObj, index) => {
                return (
                  <Accordion.Collapse eventKey="1" key={index}>
                    <Card.Body>
                      <NavToPage
                        key={index}
                        navObj={navObj}
                        setDisplaySidebar={props.setDisplaySidebar}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                );
              })}
            </Card>
          )}

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <MdDashboard /> User
            </Accordion.Toggle>
            {users.map((navObj, index) => {
              if (userType === "franchise" && index < 1) {
                return null;
              } else {
                return (
                  <Accordion.Collapse eventKey="2" key={index}>
                    <Card.Body>
                      <NavToPage
                        key={index}
                        navObj={navObj}
                        setDisplaySidebar={props.setDisplaySidebar}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                );
              }
            })}
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              <MdDashboard /> Orders
            </Accordion.Toggle>
            {Orders.map((navObj, index) => {
              if (
                userType === "enduser" ||
                ((userType === "admin" || userType === "agent") && index === 1)
              ) {
                return null;
              } else {
                return (
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <NavToPage
                        key={index}
                        navObj={navObj}
                        setDisplaySidebar={props.setDisplaySidebar}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                );
              }
            })}
          </Card>
        </Accordion>
      </Nav>
    </div>
    // </aside>
  );
};

export default Sidebar;
