import { NavLink as BSNavLink,NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";

const NavToPage = ({ navObj,setDisplaySidebar }) => {
    return (
    <NavItem>
        <BSNavLink
            tag={NavLink}
            to={navObj.to}
            onClick={() => { setDisplaySidebar(window.innerWidth > 767) }}
            activeClassName="active"
            className="inactive"
            exact={navObj.exact}
            
        >
            <navObj.Icon/>
            <span>{navObj.name} </span>
        </BSNavLink>
    </NavItem>
    )
}

export default NavToPage;