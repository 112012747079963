import React, { useEffect, useState } from "react";

import classNames from "classnames";

import userImage from "../Assets/default_pic.jpg";

import { AUTH } from "../utils/Util";

const Avatar = ({
  rounded,
  circle,
  src,
  size,
  tag: Tag,
  className,
  style,
  ...restProps
}) => {
  const [imageSrc, setImageSrc] = useState(src);
  const classes = classNames({ "rounded-circle": circle, rounded }, className);

  useEffect(() => {
    setImageSrc(AUTH.user.userPic);
  }, []);

  return (
    <Tag
      src={imageSrc}
      alt="Profile"
      style={{ width: size, height: size, ...style }}
      className={classes}
      {...restProps}
    />
  );
};

Avatar.defaultProps = {
  tag: "img",
  rounded: false,
  circle: true,
  size: 40,
  src: userImage,
  style: { objectFit: "cover" },
};

export default Avatar;
