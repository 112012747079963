import React, { useEffect, useState } from "react";
import useStateWithValidation from "../Components/CustomHooks/useStateWithValidation";
import { GlobalAuthSet } from "../utils/Util";
import { initAPICall } from "../utils/ServiceCalls";
import { ReactComponent as BrandLogo } from "../Assets/newlogo.svg";
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Container,
  Spinner,
} from "reactstrap";
import {io} from "socket.io-client";
import { ServerCofiguration } from '../utils/constants';

const SERVER = ServerCofiguration.SERVER[ServerCofiguration.ENVIRONMENT].URL;


function Login(props) {
  


  const [username, setUsername, isNameValid] = useStateWithValidation(
    (name) => name.length > 3,
    ""
  );
  const [phonenumber, setPhonenumber, isPhoneValid] = useStateWithValidation(
    (phone) => phone.length >= 10,
    ""
  );
  const [password, setPassword, isValidPassword] = useStateWithValidation(
    (pass) => pass,
    ""
  );
  const [userType, setUserType, isTypeValid] = useStateWithValidation(
    (Type) => Type,
    ""
  );
  const [formValid, setValidForm] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoader] = useState(true);
  const [User_Types, setUserTypes] = useState([]);

  const getUsetTypes = () => {
    try {
      initAPICall({
        path: "/get_user_types",
        method: "get",
        un_auth: true,
      })
        .then((response) => {
          GlobalAuthSet({
            userTypes: response.data.userTypes,
          });
          // console.log(response.data.userTypes);
          setUserTypes(response.data.userTypes);
          setErrorMessage("");
        })
        .catch((error) => {
          console.log(error);
          alert(error?.data?.error?.message);
          setErrorMessage(error?.data?.error?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    } catch (error) {
      console.log(error);
      alert(error?.data?.error?.message);
      setErrorMessage(error?.data?.error?.message);
    }
  };

  useEffect(() => {
    getUsetTypes();
  }, []);

  function validateForm() {
    let allValid =
      isNameValid &&
      isPhoneValid &&
      isTypeValid &&
      isValidPassword &&
      username.length > 3 &&
      phonenumber.length &&
      password &&
      userType;
    setValidForm(allValid);
    return allValid;
  }

  function handleSubmit(event) {
    event.preventDefault();

    validateForm();

    if (formValid) {
      initAPICall({
        path: "/login",
        method: "post",
        params: {
          username: username,
          phonenumber: phonenumber,
          password: password,
          user_type: userType,
        },
        un_auth: true,
      })
        .then((response) => {
          const newAuth ={
            token: response.data.response,
            user: {
              username,
              username_hi: response.data.username_hi ? response.data.username_hi : '',
              userType,
              userId: response.data.userId,
              userCode: response.data.user_code,
              userPic: response.data.userPic,
              supplierId: response.data.supplierId,
            },
            userTypes: response.data.userTypes,
          }

          GlobalAuthSet(newAuth);

          const socket = io(SERVER,{ extraHeaders: {"userid": response.data.userId,}}); //you only requried server adress before build not after that
            socket.on('connection', () => {
            console.log(`I'm connected with the back-end`,socket.id);
            socket.emit('message-to-supplier','abc',()=>{"I pinged the server"});
            GlobalAuthSet({...newAuth,socket});
          });
          
          socket.on("connect_error", () => {
            GlobalAuthSet({...newAuth,socket:null})
          }); 


          setErrorMessage("");
          setUserType(userType);
          props.setAuthenticated(true);
          // console.log(response.data.userTypes);
          // history.push('/display_all_products');
        })
        .catch((error) => {
          console.log(error);
          alert(error?.data?.error?.message);
          setErrorMessage(error?.data?.error?.message);

        });
    }
  }

  return (
    <>
      <Container>
        <Row
          style={{
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col md={6} lg={4}>
            <Card body style={{ backgroundColor: "#f5f5f5" }}>
              <Form>
                <div className="text-center pb-4">
                  <BrandLogo
                    width="40"
                    height="40"
                    className="navlogo"
                    style={{ width: 60, height: 60, cursor: "pointer" }}
                  />
                </div>

                <FormGroup>
                  <Label for="username">Username</Label>
                  <Input
                    type="text"
                    name="username"
                    placeholder="Enter your username"
                    value={username}
                    invalid={!isNameValid}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                  <FormFeedback>
                    Username must be greater than 3 characters
                  </FormFeedback>
                </FormGroup>

                {/* Email Field */}
                <FormGroup>
                  <Label for="phonenumber">Phone</Label>
                  <Input
                    type="number"
                    name="phonenumber"
                    placeholder="Enter your phonenumber"
                    value={phonenumber}
                    invalid={!isPhoneValid}
                    onChange={(e) => {
                      setPhonenumber(e.target.value);
                    }}
                  />
                  <FormFeedback>Please enter 10 digit number</FormFeedback>
                </FormGroup>

                {/* Password Field */}
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Enter your password . . ."
                    value={password}
                    invalid={!isValidPassword}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <FormFeedback>Please enter your password.</FormFeedback>
                </FormGroup>

                {/* User Type Field */}
                <FormGroup>
                  <Label for="userType">Select your role . . .</Label>
                  <Input
                    type="select"
                    name="userType"
                    invalid={!isTypeValid}
                    value={userType}
                    onChange={(e) => setUserType(e.target.value)}
                  >
                    <option value="">Select your role . . .</option>
                    {User_Types.map((val, index) => (
                      <option key={index} value={val}>
                        {val.charAt(0).toUpperCase() + val.slice(1)}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>Please select your role.</FormFeedback>
                </FormGroup>
                <hr />

                <Button
                  type="button"
                  style={{ backgroundColor: "#087c08" }}
                  size="lg"
                  block
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {isLoading ? <Spinner /> : "Login"}
                </Button>
                <br />
                <span
                  className="d-flex justify-content-center"
                  style={{ color: "red" }}
                >
                  {errorMessage}
                </span>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
