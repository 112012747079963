export const ServerCofiguration = {
  ENVIRONMENT: "PROD",
  SERVER:{
    DEV: {
      URL: "http://localhost:4999/",
    },
    PROD: {
      URL: "https://ayurveda-admin.onrender.com/", 
    }
  },
  ROUTE_TYPE:["AyAdminPanelUnAuthenticatedRoutes","AyAdminPanelAuthenticatedRoutes"]
}

export const SecretKey = 'secret key 123';