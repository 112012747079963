import './App.css';
import RouterPage from './Components/RouterPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AUTH} from "./utils/Util";
import Login from './pages/Login';
import { useEffect, useState } from 'react';
//import socketClient  from "socket.io-client";
//import { ServerCofiguration } from './utils/constants';
// high



function App() {
  const [authenticated,setAuthenticated]= useState(false);
  
// useEffect(()=>{
//   console.log('trying');
//   socket.on('connection', () => {
//     console.log(`I'm connected with the back-end`);
//     alert('socket connected');
// });
// });

  return (
    authenticated?
    <RouterPage />:
    <Login setAuthenticated={setAuthenticated} />
  );
}

export default App;
