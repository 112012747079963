export const getErrorMessage = (error) => {
  let errorMessage = "Something went wrong please try again."; // default error message
  try {
    let status = null;
    errorMessage = error.data.error || errorMessage;
    if (error.response && error.response.status) status = error.response.status;

    switch (status) {
      case 401:
        break;
      case 404:
        errorMessage = "Server is not reachable. Please contact administrator.";
        break;
      case 403:
        // do something when you're unauthorized to access a resource
        // console.log('AUM');
        break;
      case 500:
        // do something when your server exploded
        break;
      default:
      // handle normal errors with some alert or whatever
    }
  } catch (error) {
    // handle error
  }
  return errorMessage;
};

export const defaultDialogProps = (
  title,
  message,
  buttonLabel,
  closeCallback
) => {
  return {
    open: true,
    alertTitle: title,
    alertMessage: message,
    buttonLabel: buttonLabel,
    onRequestClose: () => {
      closeCallback();
    },
  };
};

export const defaultConfirmDialogProps = (
  title,
  message,
  buttonPositiveLabel,
  buttonNegativeLabel,
  buttonPositiveCall,
  buttonNegativeCall
) => {
  return {
    open: true,
    alertTitle: title,
    alertMessage: message,
    buttonPositiveLabel: buttonPositiveLabel,
    buttonNegativeLabel: buttonNegativeLabel,
    buttonPositiveCall: () => {
      buttonPositiveCall();
    },
    buttonNegativeCall: () => {
      buttonNegativeCall();
    },
  };
};

export const validateString = (str) => {
  return str.trim().length > 0 && str.trim().length < 100;
};

export const validateNonNegative = (num) => {
  return num > 0;
};

export const DateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export const DateOptions2 = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

export let AUTH = {
  token:
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IlUyRnNkR1ZrWDE5MjMwMFJQZlJSQytXK3R5ZjBSQTRkbVNKNS9NeXJtbVd1bWRlb0dYRFZpd0lYbVg5SzF4SnN1cDVQeVNkT1JHSFdvYzQ4WS8vYWpBPT0iLCJpYXQiOjE2MjE0MzA4OTIsImV4cCI6MTYyMjcyNjg5Mn0.gblmSa73DcYwYrbf6pfauGRNGaeBk0EU16LY4JNkQoHIhhyE1Th8AzGmJMXTvqyAdW5O01eQFR1K7cb_387W2g",
  user: {
    username: "test",
    username_hi: "",
    userType: "",
    userId: "",
    userPic: "",
    userCode: "",
  },
  userTypes: [],
  socket: null,
};

export const GlobalAuthSet = (authObj) => {
  AUTH = authObj;
};

export const invalidTextMessage =
  "Must be less than 255 characters and non empty";

export const invalidNumberMessage = "Input must be a greater than 0";

export const checkStatus = (status) => {
  if (status === 401) {
    GlobalAuthSet({
      user: { username: "test", username_hi: '', userType: "", userId: "", userCode: "" },
      TOKEN: null,
    });
    window.location.href = "/";
  }
};

export const capitalizeFirstLetter = (string) => {
  let words = string.split(" ");
  words = words.map((word) => {
    let x = word.toLowerCase();
    return x.charAt(0).toUpperCase() + x.slice(1);
  });
  return words.join(" ");
};

// image download function
export const downloadImage = async (
  imageSrc,
  imageName,
  forceDownload = false
) => {
  if (!forceDownload) {
    const link = window.document.createElement("a");
    link.href = imageSrc;
    link.download = imageName;
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
  }
  // const imageBlob await fetch(imageSrc).then((response) => response.blob());
  const imageBlob = await fetch(imageSrc)
    .then((response) => response.arrayBuffer())
    .then((buffer) => new Blob([buffer], { type: "image/jpeg" }));
  // console.log(imageBlob, URL.createObjectURL(imageBlob));
  const link = window.document.createElement("a");
  link.href = URL.createObjectURL(imageBlob);
  link.download = imageName;
  window.document.body.appendChild(link);
  link.click();
  window.document.body.removeChild(link);
};
