import Header from "../../Header";
import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Sidebar from "../Sidebar";
import { Route } from "react-router-dom/cjs/react-router-dom.min";

const Main = (props) => {
  const [displaySidebar, setDisplaySidebar] = useState(true);
  console.log(props);
  return (
    <>
      <Container
        fluid
        style={{ height: "100vh", padding: "0", overflowX: "hidden" }}
      >
        <Row className="gx-0" style={{ height: "100vh", position: "relative" }}>
          {displaySidebar &&
            (window.innerWidth > 767 ? (
              <Col
                xs={6}
                sm={4}
                md={2}
                style={{
                  borderRight: "1px solid #d2d2d2",
                }}
              >
                <Sidebar
                  displaySidebar={displaySidebar}
                  setDisplaySidebar={setDisplaySidebar}
                  {...props}
                />
              </Col>
            ) : (
              <div
                style={{
                  position: "absolute",
                  zIndex: "12",
                  background: "white",
                  padding: "12px",
                  boxShadow: "0 2px 30px grey",
                }}
              >
                <Sidebar
                  displaySidebar={displaySidebar}
                  setDisplaySidebar={setDisplaySidebar}
                  isMobile
                  {...props}
                />
              </div>
            ))}

          <Col
            xs={displaySidebar && window.innerWidth > 767 ? 6 : 12}
            sm={displaySidebar && window.innerWidth > 767 ? 8 : 12}
            md={displaySidebar && window.innerWidth > 767 ? 10 : 12}
          >
            <Header
              displaySidebar={displaySidebar}
              setDisplaySidebar={setDisplaySidebar}
            />
            <Route path={props.path}>
              <props.component {...props.componentProps} />
            </Route>
            {/* <props.component {...props.componentProps} /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Main;
