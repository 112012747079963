import React, { useState, useEffect, memo } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PageSpinner from "../Loader";
import { AUTH } from "../../utils/Util";
//import Constant from '../../Providers/ConstantProvider';
import MainLayout from "../Layout/MainLayout";

const LoginPage = React.lazy(() => import("../../pages/Login"));
const FileUploaderPage = React.lazy(() => import("../../pages/FileUpload"));
const DisplayAllProductsPage = React.lazy(() =>
  import("../../pages/DisplayAllProducts")
);
const ManjhiSpecialPage = React.lazy(() => import("../../pages/ManjhiSpecial"));
const CreateNewUserPage = React.lazy(() => import("../../pages/CreateNewUser"));
const ViewUsersPage = React.lazy(() => import("../../pages/ViewUsersPage"));
const CategoryPage = React.lazy(() =>
  import("../../pages/Categories&CommonName")
);
const BrandPage = React.lazy(() => import("../../pages/Brand"));
const DisplayProductsPage = React.lazy(() =>
  import("../../pages/DisplayProducts")
);
const ContentPage = React.lazy(() => import("../../pages/ContentPage"));
const ChannelsPage = React.lazy(() => import("../../pages/ChannelsPage"));
const YTLinksPage = React.lazy(() => import("../../pages/YTLinksPage"));
const UserDetailsPage = React.lazy(() => import("../../pages/UserDetails"));
const UserProfilePage = React.lazy(() => import("../../pages/UserProfilePage"));
const CustomerOrderPage = React.lazy(() => import("../../pages/Customers"));
const SupplierOrderPage = React.lazy(() => import("../../pages/Suppliers"));

const App = memo((props) => {
  let userType = AUTH.user.userType;
  return (
    <React.Suspense fallback={<PageSpinner />}>
      {userType && (
        <BrowserRouter>
          <Switch>
            {/* <MainLayout path="/display_all_products" component={withRouter(DisplayAllProductsPage)} {...props}  /> */}
            <MainLayout
              path="/manjhi_special"
              component={FileUploaderPage}
              {...props}
            />
            <MainLayout
              path="/create_new_backend_user"
              component={CreateNewUserPage}
              {...props}
            />
            <MainLayout
              path="/file_uploader"
              component={FileUploaderPage}
              {...props}
            />
            <MainLayout
              path="/customer_orders"
              component={FileUploaderPage}
              {...props}
            />
            <MainLayout
              path="/my_orders"
              component={FileUploaderPage}
              {...props}
            />
            <MainLayout
              path="/view_users"
              component={ViewUsersPage}
            />
            <MainLayout
              path="/categories_n_commonName"
              component={CategoryPage}
            />
            <MainLayout path="/brands" component={BrandPage} />
            <MainLayout
              path="/display_products"
              component={DisplayProductsPage}
            />
            <MainLayout
              path="/view_content"
              component={ContentPage}
            />
            <MainLayout
              path="/view_channels"
              component={ChannelsPage}
            />
            <MainLayout
              path="/view_yt_links"
              component={YTLinksPage}
            />
            <MainLayout
              path="/user_details"
              component={UserDetailsPage}
              componentProps={userType === "agent" ? { user: AUTH.user } : {}}
            />
            <MainLayout
              path="/user_profile"
              component={UserProfilePage}
            />
            <MainLayout
              path="/customers_orders"
              component={CustomerOrderPage}
            />
            <MainLayout
              path="/supplier_order"
              component={SupplierOrderPage}
            />
            <Route exact path="/">
              {userType !== "agent" ? (
                <Redirect to="/display_products" />
              ) : (
                <Redirect
                  to={{ pathname: "/user_details", state: { user: AUTH.user } }}
                />
              )}
            </Route>
          </Switch>
        </BrowserRouter>
      )}
    </React.Suspense>
  );
});

export default App;
