import "./Header.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdPersonPin,
  MdSettingsApplications,
} from "react-icons/md";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from "reactstrap";

import { GlobalAuthSet, AUTH } from "../../utils/Util";
import { useState } from "react";
import Avatar from "../Avatar";

const Header = (props) => {
  const [isOpenUserCardPopover, toggleUserCardPopover] = useState(false);

  const signOut = () => {
    GlobalAuthSet({
      user: { username: "test", username_hi: '', userType: "", userId: "", userCode: "" },
      TOKEN: null,
    });
    window.location.href = "/";
  };


  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
      props.setDisplaySidebar(window.innerWidth > 767);
    };

    handleResize();

    window.addEventListener('resize', handleResize);


    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="gx-0" style={{ overflow: "hidden", border: "1px solid #d2d2d2", position: 'sticky', top: '0', background: '#ffffff', zIndex: '10' }}>
      <Navbar light expand>
        <Nav navbar className="mr-2">
          <Button
            outline
            onClick={() => props.setDisplaySidebar(!props.displaySidebar)}
            className="sandwich_icon"
          >
            <MdClearAll size={25} />
          </Button>
        </Nav>

        <Nav navbar>
          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={() => toggleUserCardPopover(!isOpenUserCardPopover)}
                className="can-click"
              />
            </NavLink>

            <Popover
              className="p-0 border-0"
              isOpen={isOpenUserCardPopover}
              placement="bottom-end"
              style={{ minWidth: 250 }}
              target="Popover2"
              toggle={toggleUserCardPopover}
            >
              <PopoverBody className="p-0 border-light">
                <ListGroup flush>
                  <ListGroupItem tag="button" action className="border-light">
                    <Link
                      to="/user_profile"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <MdPersonPin />
                      {AUTH.user.username} ({AUTH.user.userType})
                    </Link>
                  </ListGroupItem>

                  <ListGroupItem tag="button" action className="border-light">
                    <MdInsertChart /> Stats
                  </ListGroupItem>

                  <ListGroupItem tag="button" action className="border-light">
                    <MdMessage /> Messages
                  </ListGroupItem>

                  <ListGroupItem tag="button" action className="border-light">
                    <MdSettingsApplications /> Settings
                  </ListGroupItem>

                  <ListGroupItem tag="button" action className="border-light">
                    <MdHelp /> Help
                  </ListGroupItem>

                  <ListGroupItem
                    tag="button"
                    action
                    className="border-light"
                    onClick={() => {
                      signOut();
                    }}
                  >
                    <MdExitToApp /> Signout
                  </ListGroupItem>
                </ListGroup>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
};

export default Header;
